'use client'

import React, { useRef } from 'react'
import { DEFAULT_INTERSECTION_OPTIONS, useScrollIntersection } from './utils/windowScroll'

interface RevealOptions {
  id?: string
  className?: string
  children?: any
  intersectOptions?: any
  once?: boolean
  delay?: number
  duration?: number
  translateX?: number
  translateY?: number
  scale?: number
  opacity?: number
  func?: string
  style?: any
  onClick?: any
}

const DEFAULT_PROPS: RevealOptions = {
  id: '',
  className: '',
  intersectOptions: DEFAULT_INTERSECTION_OPTIONS,
  once: false,
  delay: 0,
  duration: 500,
  translateX: 0,
  translateY: 0,
  scale: 1,
  opacity: 0,
  func: 'ease-in-out',
  style: {},
  onClick: null
}

/**
 * Reveal Wrapper Component
 * ---------------------------------------------------------------------------------------------------------------------
 */
export default function Reveal(props: RevealOptions) {

  props = Object.assign({}, DEFAULT_PROPS, props)

  const ref = useRef<any>()
  const { show } = useScrollIntersection(ref)

  const [shown, setShown] = React.useState(false)

  React.useEffect(() => {
    if (show) {
      setShown(true)
    } else if (!props.once) {
      setShown(false)
    }
  }, [show])

  // Rendering
  //--------------------------------------------------------------------------------------------------------------------
  return (
    <div ref={ref}
      style={Object.assign({
        transform: shown ? 'none' : (
          'scale(' + props.scale + ') ' +
          'translateX(' + props.translateX + 'px) ' +
          'translateY(' + props.translateY + 'px)'
        ),
        transitionDelay: (props.delay || 0) + 'ms',
        transitionDuration: (props.duration || 500) + 'ms',
        transitionTimingFunction: props.func,
        opacity: shown ? 1 : props.opacity
      }, props.style)}
      className={(props.className ? props.className : '') + ' transition-all'} onClick={props.onClick}>
      {props.children}
    </div>
  )

}
