import { cn } from '@/lib/utils/utils'
import MD from '../markdown'

export function SectionTitle({ title, desc, className, titleText = 'text-2xl sm:text-3xl', titleMaxWidth = 'max-w-3xl', descText = 'sm:text-lg', descMaxWidth = 'max-w-3xl', mainTitle = false }: { title: string, desc?: string, contrast?: boolean, className?: string, titleText?: string, titleMaxWidth?: string, descText?: string, descMaxWidth?: string, mainTitle?: boolean }) {

  return (
    <div id='SECTION_TITLE' className={cn('flex w-full flex-col items-center gap-8 pt-4', className)}>
      {title && (mainTitle ? <h1 id='TITLE' className={cn('text-center font-bold', titleText, titleMaxWidth)}>
        <MD textAlign='center'>{title}</MD>
      </h1> : <h2 id='SUB_TITLE' className={cn('text-center font-bold', titleText, titleMaxWidth)}>
        <MD textAlign='center'>{title}</MD>
      </h2>)}
      {desc && <div id='DESC' className={cn('text-center', descText, descMaxWidth)}>
        <MD textAlign='center'>{desc}</MD>
      </div>}
    </div>
  )
}
