import { cn } from '@/lib/utils/utils'

export type Variant = 'plain' | 'outline'

export type ShapeOptions = {
  left?: string
  right?: string
  top?: string
  bottom?: string
  size: number
  strokeWidth?: number
  opacity?: number
  variant: Variant
  gradient?: boolean
  className?: string
}

export const DEFAULT_SHAPE_OPTIONS: Partial<ShapeOptions> = {
  left: 'auto',
  right: 'auto',
  top: 'auto',
  bottom: 'auto',
  strokeWidth: 10,
  opacity: 100,
  variant: 'outline',
  gradient: false,
  className: ''
}

export type ShapeDef = ShapeOptions & {
  path: string
  viewBox?: string
}

export const Shape = ({
  path,
  viewBox = '0 0 100 100',
  ...shapeOptions
}: ShapeDef) => {
  const opt = { ...DEFAULT_SHAPE_OPTIONS, ...shapeOptions, path, viewBox }
  return <svg xmlns='http://www.w3.org/2000/svg'
    viewBox={opt.viewBox}
    className={cn('absolute', opt.className)}
    style={{
      left: typeof opt.left === 'string' ? opt.left : 'auto',
      right: typeof opt.right === 'string' ? opt.right : 'auto',
      top: typeof opt.top === 'string' ? opt.top : 'auto',
      bottom: typeof opt.bottom === 'string' ? opt.bottom : 'auto',
      opacity: typeof opt.opacity === 'number' ? opt.opacity : 1,
      width: opt.size + 'px',
      height: opt.size + 'px'
    }}>
    <defs>
      <linearGradient id="gradientPrimary" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{
          stopColor: 'var(--primary)',
          stopOpacity: '1'
        }} />
        <stop offset="100%" style={{
          stopColor: 'var(--primary-bright)',
          stopOpacity: '1'
        }} />
      </linearGradient>
    </defs>
    <path
      d={opt.path}
      fill={opt.variant === 'outline' ? 'none' : 'var(--primary)'}
      stroke={opt.gradient ? 'url(#gradientPrimary)' : 'var(--primary)'}
      strokeWidth={opt.strokeWidth}
      strokeLinejoin='round'
      strokeLinecap='round' />
  </svg>
}
