import * as React from 'react'

import { cn } from '@/lib/utils/utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-border bg-input px-3 py-2 text-sm ' +
          'placeholder:text-muted-foreground ' +
          'file:border-0 file:bg-input file:text-sm file:font-medium ' +
          'focus-visible:outline-none focus-visible:ring-none ' +
          'disabled:cursor-not-allowed disabled:opacity-70',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
