import { cn } from '@/lib/utils/utils'
import { Shape, ShapeOptions } from './shape'
import './shapes.css'

export const Bar = ({ left, right, top, bottom, width, height, opacity, variant = 'plain' }: { left?: string, right?: string, top?: string, bottom?: string, width: number, height: number, opacity?: number, variant: 'plain' | 'outline' }) => {
  return <div
    className={cn('absolute rotate-45 rounded-full', variant === 'outline' ? 'box' : '')}
    style={{
      left: typeof left === 'string' ? left : 'auto',
      right: typeof right === 'string' ? right : 'auto',
      top: typeof top === 'string' ? top : 'auto',
      bottom: typeof bottom === 'string' ? bottom : 'auto',
      width: width + 'px',
      height: height + 'px',
      opacity: typeof opacity === 'number' ? opacity : 1,
      background: variant === 'outline' ? '' : 'linear-gradient(45deg, var(--primary-bright), var(--primary))',
      backgroundColor: variant === 'outline' ? 'transparent' : ''
    }} />
}

export const Circle = (options: ShapeOptions) => <Bar {...{width: options.size, height: options.size, ...options}} />

export const Triangle = (options: ShapeOptions) => <Shape {...options}
  path='M0 -100 L86.6 50 L-86.6 50Z'
  viewBox='-110 -110 220 220'
  strokeWidth={20} />

export const Square = (options: ShapeOptions) => <Shape {...options}
  path='M100 -100 L100 100 L-100 100 L-100 -100Z'
  viewBox='-110 -110 220 220'
  strokeWidth={20} />

export const Pentagon = (options: ShapeOptions) => <Shape {...options}
  path='M0 -100 L95 -31 L59 81 L-59 81 L-95 -31Z'
  viewBox='-110 -110 220 220'
  strokeWidth={20} />

export const Octogon = (options: ShapeOptions) => <Shape {...options}
  path='M25 5 L54 5 L74 25 L74 54 L54 74 L25 74 L5 54 L5 25 Z'
  viewBox='0 0 80 80'
  strokeWidth={8} />

export const Hexagon = (options: ShapeOptions) => <Shape {...options}
  path='M35 5 L95 5 L125 57 L95 109 L35 109 L5 57 Z'
  viewBox='0 0 130 114'
  className='rotate-[30deg]'
  strokeWidth={12} />

export const Sinusoids = (options: ShapeOptions) => <Shape {...options}
  path='M5 0 C 10 -30, 45 -30, 50 0 C 55 30, 90 30, 95 0 C 100 -30, 135 -30, 140 0 C 145 30, 180 30, 185 0 C 190 -30, 225 -30, 230 0 C 235 30, 270 30, 275 0'
  viewBox='0 -50 280 100'
  strokeWidth={10}
  gradient={true} />

export const PentagonGoo = ({ left, right, top, bottom, size, opacity, variant = 'plain' }: { left?: string, right?: string, top?: string, bottom?: string, size: number, opacity?: number, variant: 'plain' | 'outline' }) => {
  return <div
    className='absolute'
    style={{
      left: typeof left === 'string' ? left : 'auto',
      right: typeof right === 'string' ? right : 'auto',
      top: typeof top === 'string' ? top : 'auto',
      bottom: typeof bottom === 'string' ? bottom : 'auto',
      opacity: typeof opacity === 'number' ? opacity : 1,
      width: size + 'px',
      height: size + 'px'
    }}>
    <svg className='absolute hidden' width='0' height='0' xmlns='http://www.w3.org/2000/svg' version='1.1'>
      <defs>
        <filter id='round'>
          <feGaussianBlur in='SourceGraphic' stdDeviation='5' result='blur' />
          <feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo' />
          <feComposite in='SourceGraphic' in2='goo' operator='atop' />
        </filter>
      </defs>
    </svg>
    <div className='hexagone' style={{
      color: 'var(--primary)',
      width: '100%',
      filter: 'url(#round)'
    }}></div>
  </div>
}

export const Dots = ({ left, right, top, bottom, size, opacity, variant = 'plain' }: { left?: string, right?: string, top?: string, bottom?: string, size: number, opacity?: number, variant: 'plain' | 'outline' }) => {
  return <svg xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 100 100'
    className='absolute'
    style={{
      left: typeof left === 'string' ? left : 'auto',
      right: typeof right === 'string' ? right : 'auto',
      top: typeof top === 'string' ? top : 'auto',
      bottom: typeof bottom === 'string' ? bottom : 'auto',
      opacity: typeof opacity === 'number' ? opacity : 1,
      width: size + 'px',
      height: size + 'px'
    }}>
    {Array.from({ length: 5 }).map((_, rowIndex) =>
      Array.from({ length: 5 }).map((_, colIndex) =>
        <circle key={rowIndex * 5 + colIndex} cx={colIndex * 22.5 + 5} cy={rowIndex * 22.5 + 5} r='4' fill='var(--primary)' />
      )
    )}
  </svg>
}
