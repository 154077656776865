'use client'

import { useScreen } from '@/components/utils/windowSize'
import { Bar, Dots, Pentagon, Sinusoids } from '../shapes'

export function LBPromiseBG({ fixed = false }: { fixed?: boolean }) {

  const screen = useScreen()

  return (
    <div id='FORMS' className='absolute left-0 top-0 z-0 h-screen max-h-full w-full overflow-hidden'>
      <Bar left={'12px'} top={'-8px'} width={24} height={440} variant='plain' opacity={0.4} />
      <Bar left={'20px'} bottom={'-24px'} width={50} height={440} variant='outline' opacity={0.5} />
      <Bar right={'20px'} bottom={'-20px'} width={24} height={440} variant='plain' opacity={0.5} />
      <Bar right={'16px'} bottom={'50px'} width={40} height={440} variant='outline' opacity={0.3} />
      <Bar right={'8px'} top={'-10px'} width={50} height={440} variant='plain' opacity={0.5} />
      <Dots left={screen.md ? '225px' : '20px'} top={screen.md ? '140px' : '20px'} size={screen.md ? 150 : 80} variant='plain' opacity={0.6} />
      <Pentagon right={screen.md ? '255px' : '20px'} top={screen.md ? '50px' : '20px'} size={screen.md ? 100 : 50} variant='outline' opacity={0.6} />
      <Sinusoids right={'-30px'} top={'80px'} size={150} variant='outline' opacity={0.6} className='rotate-[135deg]' />

      <div id='BLUR' className='z-1 relative m-auto h-full w-full'
        style={{
          background: screen.md ?
            ('radial-gradient(circle, var(--background) 0%, var(--backgroundInter) 60%, rgba(0,0,0,0) 80%)') :
            'radial-gradient(circle, var(--background) 0%, var(--backgroundInter) 70%, rgba(0,0,0,0) 85%)'
        }} />
    </div>
  )
}
